/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-mutating-methods */
import React from 'react'
import './styles.scss'
import i18next from 'i18next'
import SwipeableViews from 'react-swipeable-views'
import Icon from '../../../../utils/icon'
import {getStatusIcon, isWeb} from '../../../../utils/utility'
import Tab from '../../atoms/Tab'
import {
  isEvseSite,
  isSavingsModeDisabledForEmea,
  systemWithEnchargeAndGridAgnostic,
  showSavingsMode,
  hasEncharge,
  isDTSupportedSite,
  isHemsSite,
  isNem3Supported,
  isNem3TariffSupported,
  isPVOnlySite,
  isPvAndCtWithEvseSite,
  isCountryDE,
  IQGWScheduleSupportWithSavingsMode,
  hasSystemCard,
} from '../../../../utils/siteDetails'
import {
  renderSavingsModePeak,
  renderSavingsModeOffPeakWithSolar,
  renderSavingsModeOffPeakWithoutSolar,
  renderSelfConsumption,
  renderFullBackup,
  renderSelfConsumptionModeDTSupported,
  renderSavingsModePriceAreNegativeDTSupported,
  renderSavingsModePriceAreLowDTSupported,
  renderSavingsModePriceAreHighDTSupported,
  renderNEMSavingsModeOffPeakWithoutSolar,
  renderNEMSavingsModePeak,
  renderSelfConsumptionModeInPVOnlyForDTSupported,
  renderSavingsModePositiveRatesInPVOnlyForDTSupported,
  renderSavingsModeNegativeRatesInPVOnlyForDTSupported,
  renderSelfConsumptionModeInPVOnlyForEvseSite,
  renderSelfConsumptionModeDTGermanySupported,
  renderSavingsModePriceAreNegativeDTGermanySupported,
  renderSavingsModePriceAreLowDTGermanySupported,
  renderSavingsModePriceAreHighDTGermanySupported,
  renderSavingsModeNegativeRatesInPVOnlyForDTSupportedWithDevices,
  renderSavingsModePriceAreLowInPVOnlyForDTSupportedWithDevices,
  renderSavingsModePriceAreHighInPVOnlyForDTSupportedWithDevices,
  renderSelfConsumptionModeInPVOnlyForDTSupportedWithDevices,
} from '../../atoms/LiveStatusProfiles'
import {LIVE_STATUS, TUTORIAL_PROFILE_TYPE} from '../../../../utils/constants'

// eslint-disable-next-line no-unused-vars
function showStatusIcon(statusType, type) {
  const iconType = getStatusIcon(type)

  if (statusType === 'grid' || statusType === 'generator') {
    return <Icon className="mqtt-status-icon" src={iconType} />
  }
  return type === LIVE_STATUS.UNKNOWN ? <Icon src="systemError" /> : <span className={type} />
}

class ChooseRightProfile extends React.Component {
  static getTabTitle(type) {
    switch (type) {
      case TUTORIAL_PROFILE_TYPE.AI_SAVINGS:
        return i18next.t('bp436')
      case TUTORIAL_PROFILE_TYPE.SELF_CONSUMPTION:
        return i18next.t('bp22')
      case TUTORIAL_PROFILE_TYPE.COST_SAVINGS:
        return i18next.t('bp30')
      case TUTORIAL_PROFILE_TYPE.BACKUP_ONLY:
        return i18next.t('bp16')
      default:
        return null
    }
  }

  constructor() {
    super()

    this.state = {
      selectedViewIndex: 0,
      tutorialView: false,
      views: [],
    }

    this.myRef = React.createRef()
    this.eiToggle = this.eiToggle.bind(this)
  }

  componentDidMount() {
    if (!isWeb()) {
      this.canShowTutorial(true)
    }
    this.prepareSwipeableViews()
  }

  handleChangeIndex = index => {
    this.setState({
      selectedViewIndex: index,
    })
  }

  setTabsActive(arr) {
    return arr.includes(this.state.selectedViewIndex) ? 'active' : ''
  }

  tutorialClick(val) {
    if (val === 'upNext') {
      this.setState({tutorialView: 'gotIt'})
    } else if (val === 'gotIt') {
      // cookies.set('bp-tutorial', 'dismissed', { expires: 2147483647 })
      this.canShowTutorial(false)
    }
  }

  canShowTutorial(showTutorial) {
    // const showTutorial = cookies.get('bp-tutorial') !== 'dismissed'
    if (showTutorial && (showSavingsMode() || systemWithEnchargeAndGridAgnostic())) {
      this.setState({tutorialView: 'upNext'})
    } else {
      this.setState({tutorialView: false})
    }
  }

  eiToggle(checked) {
    const index = checked ? 2 : 1
    this.handleChangeIndex(index)
  }

  prepareSwipeableViews() {
    let views = []

    if (IQGWScheduleSupportWithSavingsMode()) {
      if (isDTSupportedSite() && isCountryDE() && hasEncharge()) {
        views = [
          {
            type: TUTORIAL_PROFILE_TYPE.AI_SAVINGS,
            content: renderSavingsModePriceAreNegativeDTGermanySupported(this.myRef),
          },
        ]
        views.push({type: TUTORIAL_PROFILE_TYPE.AI_SAVINGS, content: renderSavingsModePriceAreLowDTGermanySupported()})
        views.push({
          type: TUTORIAL_PROFILE_TYPE.AI_SAVINGS,
          content: renderSavingsModePriceAreHighDTGermanySupported(),
        })
        views.push({
          type: TUTORIAL_PROFILE_TYPE.SELF_CONSUMPTION,
          content: renderSelfConsumptionModeDTGermanySupported(),
        })
      } else if (isDTSupportedSite() || isHemsSite()) {
        if (isPVOnlySite()) {
          if (hasSystemCard()) {
            views = [
              {
                type: TUTORIAL_PROFILE_TYPE.AI_SAVINGS,
                content: renderSavingsModeNegativeRatesInPVOnlyForDTSupportedWithDevices(this.myRef),
              },
            ]
            views.push({
              type: TUTORIAL_PROFILE_TYPE.AI_SAVINGS,
              content: renderSavingsModePriceAreLowInPVOnlyForDTSupportedWithDevices(),
            })
            views.push({
              type: TUTORIAL_PROFILE_TYPE.AI_SAVINGS,
              content: renderSavingsModePriceAreHighInPVOnlyForDTSupportedWithDevices(),
            })
            views.push({
              type: TUTORIAL_PROFILE_TYPE.SELF_CONSUMPTION,
              content: renderSelfConsumptionModeInPVOnlyForDTSupportedWithDevices(),
            })
          } else {
            views = [
              {
                type: TUTORIAL_PROFILE_TYPE.AI_SAVINGS,
                content: renderSavingsModePositiveRatesInPVOnlyForDTSupported(this.myRef),
              },
            ]
            views.push({
              type: TUTORIAL_PROFILE_TYPE.AI_SAVINGS,
              content: renderSavingsModeNegativeRatesInPVOnlyForDTSupported(),
            })
            views.push({
              type: TUTORIAL_PROFILE_TYPE.SELF_CONSUMPTION,
              content: renderSelfConsumptionModeInPVOnlyForDTSupported(),
            })
            this.setState({views})
            return
          }
        } else {
          // Here, If the site id is DT-Supported, the saving block will be displayed.
          views = [
            {type: TUTORIAL_PROFILE_TYPE.AI_SAVINGS, content: renderSavingsModePriceAreNegativeDTSupported(this.myRef)},
          ]
          views.push({type: TUTORIAL_PROFILE_TYPE.AI_SAVINGS, content: renderSavingsModePriceAreLowDTSupported()})
          views.push({type: TUTORIAL_PROFILE_TYPE.AI_SAVINGS, content: renderSavingsModePriceAreHighDTSupported()})
          views.push({type: TUTORIAL_PROFILE_TYPE.SELF_CONSUMPTION, content: renderSelfConsumptionModeDTSupported()})
          if (systemWithEnchargeAndGridAgnostic())
            views.push({type: TUTORIAL_PROFILE_TYPE.BACKUP_ONLY, content: renderFullBackup()}) // if 'full back' tab is present need to show its corresponding slide
        }
      } else if (isNem3Supported() && isNem3TariffSupported()) {
        views = [{type: TUTORIAL_PROFILE_TYPE.AI_SAVINGS, content: renderNEMSavingsModePeak(this.myRef)}]
        views.push({type: TUTORIAL_PROFILE_TYPE.AI_SAVINGS, content: renderNEMSavingsModeOffPeakWithoutSolar()})
        views.push({type: TUTORIAL_PROFILE_TYPE.SELF_CONSUMPTION, content: renderSelfConsumption()})
        if (systemWithEnchargeAndGridAgnostic())
          views.push({type: TUTORIAL_PROFILE_TYPE.BACKUP_ONLY, content: renderFullBackup()})
      } else if (isPvAndCtWithEvseSite()) {
        if (showSavingsMode()) {
          views = [{type: TUTORIAL_PROFILE_TYPE.AI_SAVINGS, content: renderSavingsModePeak(this.myRef)}]
          views.push({type: TUTORIAL_PROFILE_TYPE.AI_SAVINGS, content: renderSavingsModeOffPeakWithSolar()})
          views.push({type: TUTORIAL_PROFILE_TYPE.AI_SAVINGS, content: renderSavingsModeOffPeakWithoutSolar()})
          views.push({
            type: TUTORIAL_PROFILE_TYPE.SELF_CONSUMPTION,
            content: renderSelfConsumptionModeInPVOnlyForEvseSite(),
          })
        } else {
          views = [
            {
              type: TUTORIAL_PROFILE_TYPE.SELF_CONSUMPTION,
              content: renderSelfConsumptionModeInPVOnlyForEvseSite(this.myRef),
            },
          ]
        }
        if (systemWithEnchargeAndGridAgnostic())
          views.push({type: TUTORIAL_PROFILE_TYPE.BACKUP_ONLY, content: renderFullBackup()})
      } else {
        if (showSavingsMode()) {
          views = [{type: TUTORIAL_PROFILE_TYPE.AI_SAVINGS, content: renderSavingsModePeak(this.myRef)}]
          views.push({type: TUTORIAL_PROFILE_TYPE.AI_SAVINGS, content: renderSavingsModeOffPeakWithSolar()})
          views.push({type: TUTORIAL_PROFILE_TYPE.AI_SAVINGS, content: renderSavingsModeOffPeakWithoutSolar()})
          views.push({type: TUTORIAL_PROFILE_TYPE.SELF_CONSUMPTION, content: renderSelfConsumption()})
        } else {
          views = [{type: TUTORIAL_PROFILE_TYPE.SELF_CONSUMPTION, content: renderSelfConsumption(this.myRef)}]
        }
        if (systemWithEnchargeAndGridAgnostic())
          views.push({type: TUTORIAL_PROFILE_TYPE.BACKUP_ONLY, content: renderFullBackup()})
      }
    } else if (isPvAndCtWithEvseSite()) {
      views = [
        {
          type: TUTORIAL_PROFILE_TYPE.SELF_CONSUMPTION,
          content: renderSelfConsumptionModeInPVOnlyForEvseSite(this.myRef),
        },
      ]
      if (showSavingsMode()) {
        views.push({type: TUTORIAL_PROFILE_TYPE.COST_SAVINGS, content: renderSavingsModePeak()})
        views.push({type: TUTORIAL_PROFILE_TYPE.COST_SAVINGS, content: renderSavingsModeOffPeakWithSolar()})
        views.push({type: TUTORIAL_PROFILE_TYPE.COST_SAVINGS, content: renderSavingsModeOffPeakWithoutSolar()})
      }
      if (systemWithEnchargeAndGridAgnostic())
        views.push({type: TUTORIAL_PROFILE_TYPE.BACKUP_ONLY, content: renderFullBackup()})
    } else {
      views = [{type: TUTORIAL_PROFILE_TYPE.SELF_CONSUMPTION, content: renderSelfConsumption(this.myRef)}]
      if (showSavingsMode()) {
        views.push({type: TUTORIAL_PROFILE_TYPE.COST_SAVINGS, content: renderSavingsModePeak()})
        views.push({type: TUTORIAL_PROFILE_TYPE.COST_SAVINGS, content: renderSavingsModeOffPeakWithSolar()})
        views.push({type: TUTORIAL_PROFILE_TYPE.COST_SAVINGS, content: renderSavingsModeOffPeakWithoutSolar()})
      }
      if (systemWithEnchargeAndGridAgnostic())
        views.push({type: TUTORIAL_PROFILE_TYPE.BACKUP_ONLY, content: renderFullBackup()})
    }

    this.setState({views})
  }

  renderTutorialSelectProfile() {
    return (
      <div className="bp__tutorial">
        <div className="bp__tutorial__wrapper">
          <Icon src="upHand" />
          <div className="bp__tutorial__desc">{i18next.t('bp146')}</div>
          <button type="button" onClick={() => this.tutorialClick('upNext')}>
            {i18next.t('bp147')}
          </button>
        </div>
      </div>
    )
  }

  renderTutorialSwipeLeftRight() {
    return (
      <div className="bp__tutorial bp__tutorial__swipe-left-right">
        <div className="bp__tutorial__swipe-left-right__wrapper">
          <Icon src="swipeLeftRightHand" />
          <div className="bp__tutorial__desc">{i18next.t('bp120')}</div>
          <button type="button" onClick={() => this.tutorialClick('gotIt')}>
            {i18next.t('bp121')}
          </button>
        </div>
      </div>
    )
  }

  renderTutorial() {
    const {tutorialView} = this.state

    if (tutorialView == 'upNext') {
      return this.renderTutorialSelectProfile()
    }
    if (tutorialView == 'gotIt') {
      return this.renderTutorialSwipeLeftRight()
    }
    return null
  }

  renderSwipeableViews() {
    const {views} = this.state
    if (views.length) return views.map(view => view.content)
    return null
  }

  renderContentsNav() {
    const {selectedViewIndex, views} = this.state
    const navItems = []
    const totalContent = views.length

    for (let index = 0; index < totalContent; index += 1) {
      navItems.push(
        <a
          role="button"
          tabIndex={0}
          aria-label="content-button"
          key={`bp__contents__nav${index}`}
          onClick={() => this.handleChangeIndex(index)}
          onKeyDown={() => this.handleChangeIndex(index)}
        >
          <li className={selectedViewIndex === index ? 'bp__contents__nav__active' : 'bp__contents__nav__item'} />
        </a>
      )
    }

    return <ul>{navItems}</ul>
  }

  renderTabs() {
    const {views} = this.state
    if (views.length) {
      const uniqueTypes = new Set()
      const indexMap = {}

      views.forEach((item, index) => {
        uniqueTypes.add(item.type)
        if (!indexMap[item.type]) {
          indexMap[item.type] = []
        }
        indexMap[item.type].push(index)
      })

      return Array.from(uniqueTypes).map(type => (
        <Tab
          key={type}
          tabName={ChooseRightProfile.getTabTitle(type)}
          activeClass={this.setTabsActive(indexMap[type])}
          changeIndex={indexMap[type][0]}
          click={this.state.tutorialView ? () => {} : this.handleChangeIndex}
        />
      ))
    }
    return null
  }

  render() {
    const {selectedViewIndex, tutorialView} = this.state

    return (
      <div className={`learn-more-about-profiles ${isSavingsModeDisabledForEmea() ? 'emeaGriedTied' : ''}`}>
        <div className={`bp__wrapper ${tutorialView ? 'bp__has__tutorial' : ''}`}>
          <div className={`bp__contents ${isEvseSite() ? 'bp__has__evse' : ''}`}>
            <div className="bp__contents__tabs">{this.renderTabs()}</div>
            <SwipeableViews enableMouseEvents index={selectedViewIndex} onChangeIndex={this.handleChangeIndex}>
              {this.renderSwipeableViews()}
            </SwipeableViews>
            {tutorialView && this.renderTutorial()}
          </div>

          <div className="bp__contents__nav">
            {(showSavingsMode() || systemWithEnchargeAndGridAgnostic()) && this.renderContentsNav()}
          </div>
        </div>
      </div>
    )
  }
}

ChooseRightProfile.propTypes = {}

ChooseRightProfile.defaultProps = {}

export default ChooseRightProfile
